const links = [
    { title: 'Discord', url: 'https://discord.gg/xXUZFTuzSw' },
    { title: 'GitHub', url: 'https://github.com/elide-us' },
    { title: 'TikTok', url: 'https://www.tiktok.com/@elide.us' },
    { title: 'BlueSky', url: 'https://bsky.app/profile/elideusgroup.com' },
    { title: 'Instagram', url: 'https://www.instagram.com/elide.us/profilecard/?igsh=MWR0dGhxdzI1b2E0Mg==' },
    { title: 'Suno', url: 'https://suno.com/@elideus' }
]

export default links;
